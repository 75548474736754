<template>
  <section class="login-content">
    <div class="logo text-center">
      <LoginLogoIcon />
      <div class="bar">
        <div class="progress"></div>
      </div>
      <LoginLogoName />
    </div>
    <div class="login-box register">
      <form class="login-form" v-on:submit="login">
        <h3 class="login-head">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path
              d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"
            ></path>
          </svg>
          <span>REGISTER</span>
        </h3>
        <div class="form-group">
          <div class="row">
            <div class="col-md-4">
              <label class="control-label">FIRST NAME</label>
              <input
                class="form-control"
                type="text"
                name="firstname"
                v-model="user.firstname"
                autofocus=""
              />
            </div>
            <div class="col-md-4">
              <label class="control-label">LAST NAME</label>
              <input
                class="form-control"
                type="text"
                name="lastname"
                v-model="user.lastname"
              />
            </div>
            <div class="col-md-4">
              <label class="control-label">COMPANY</label>
              <input
                class="form-control"
                type="text"
                name="company"
                v-model="user.company"
              />
            </div>
          </div>
        </div>

        <!-- <input type="number" name="admin" v-model="user.admin" /><br /> -->
        <div class="form-group">
          <div class="row">
            <div class="col-md-6">
              <label class="control-label">EMAIL ADDRESS</label
              ><input
                class="form-control"
                type="email"
                placeholder="name@email.com"
                name="email"
                v-model="user.email"
              />
            </div>
            <div class="col-md-6">
              <label class="control-label">PHONE NUMBER</label
              ><input
                class="form-control"
                type="text"
                placeholder="02/04"
                name="phone"
                v-model="user.phone"
              />
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-md-6">
              <label class="control-label">PASSWORD</label
              ><input
                class="form-control"
                type="password"
                name="password"
                placeholder="Password"
                v-model="user.password"
              />
            </div>
            <div class="col-md-6">
              <label class="control-label">PASSWORD CONFIRM</label>
              <input
                class="form-control"
                type="password"
                name="password-confirm"
                placeholder="Password confirm"
                v-model="user.passwordconfirm"
              />
            </div>
          </div>
        </div>

        <div class="form-group btn-containerx">
          <button class="btn btn-primary btn-block" type="submit">
            <i class="fa fa-sign-in fa-lg fa-fw"></i>REGISTER
          </button>
        </div>
        <div class="form-group">
          <div class="utility">
            <p class="semibold-text">
              <a class="flip" href="/#/login">Login</a>
            </p>
          </div>
        </div>
      </form>
      <div class="overlay overlay--50" v-if="sending">
        <div class="m-loader m-loader--80">
          <svg class="m-circular" viewBox="25 25 50 50">
            <circle
              class="path"
              cx="50"
              cy="50"
              r="20"
              fill="none"
              stroke-width="4"
              stroke-miterlimit="10"
            ></circle>
          </svg>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
// @ is an alias to /src
import router from "@/router";
import api from "@/lib/api";
import { gsap, CSSPlugin } from "gsap/all";
gsap.registerPlugin(CSSPlugin);
import LoginLogoIcon from "@/components/LoginLogoIcon.vue";
import LoginLogoName from "@/components/LoginLogoName.vue";

export default {
  name: "Register",
  components: {
    LoginLogoIcon,
    LoginLogoName,
  },
  data() {
    return {
      user: {
        // firstname: "Dns",
        // lastname: "Website",
        // email: "dns@fewd.com.au",
        // phone: "0422969683",
        // admin: 0,
        // password: "Zbddecba1",
        // passwordconfirm: "Zbddecba1",
        firstname: "",
        lastname: "",
        company: "",
        email: "",
        phone: "",
        admin: 0,
        password: "",
        passwordconfirm: "",
      },
      csrf: "",
      sending: false,
    };
  },
  beforeCreate() {
    api
      .get("/api/login")
      .then((response) => {
        console.log("res", response);
        this.csrf = response.data.csrfToken;
      })
      .catch((errors) => {
        console.log(errors.response);
        console.log(errors.response.data.error);
      });
  },
  mounted() {
    var tl = gsap.timeline({ delay: 1 });

    tl.from(".logo-icon", { duration: 0.2, scale: 0.6, opacity: 0 });
    tl.to(".logo-icon", { duration: 0.1, scale: 1 });
    tl.to(".logo-icon", { duration: 0.1, scale: 0.9 });
    tl.to(".logo-icon", { duration: 0.3, scale: 1 });
    tl.from(
      ".logo-icon path",
      {
        duration: 0.3,
        fill: "#ccc",
        stagger: 0.1,
      },
      "-=0.4"
    );
    tl.set(".logo-icon", { duration: 0, delay: 1 });
    tl.from(".logo-name", { duration: 0.2, y: 10, opacity: 0 });
    tl.set(".login-box", {
      onComplete: () => {
        document.querySelector(".login-box").classList.add("height");
      },
    });

    tl.from(".bar", { duration: 0.2, opacity: 0 }, 0.7);
    tl.to(".progress", { duration: 1, delay: 0.2, width: "200px" }, 0.9);
    tl.to(".bar", { duration: 0.2, opacity: 0 }, 2.3);
  },
  methods: {
    login(e) {
      e.preventDefault();

      if (
        this.user.firstname === "" ||
        this.user.lastname === "" ||
        this.user.email === "" ||
        this.user.phone === "" ||
        this.user.password === "" ||
        this.user.passwordconfirm === ""
      ) {
        this.error("Error", "Please fill in all your details.");
        return;
      }

      this.sending = true;

      this.$store
        .dispatch("REGISTERNEWUSER", { _csrf: this.csrf, ...this.user })
        .then((response) => {
          console.log("User saved to store.");
          this.success("Success", response.data);
          router.push("/register/verify");
        })
        .catch((error) => {
          this.sending = false;
          console.log("Ah snap, something borked.", error.response);
          this.error("Error", error.response.data);
        });
    },
  },
};
</script>
